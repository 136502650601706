export const environment = {
  appVersion: require('../../package.json').version + '-dev',
  production: false,
  env: 'dev',
  APIUrl: 'https://thesparktech.sparktechdev.org/api/en-in/',
  orgURL: 'https://thesparktech.sparktechdev.org',
  domain: 'sparktechdev.org',
  loginDomain: 'https://accounts.sparktechdev.org',
  myAccountDomain: 'https://myaccount.sparktechdev.org',
  passphrase: '{(<^All|/\|eB@pp$^>)&[~$T#25218~]}',
  rzp_key: "rzp_test_NnNBGwFyFy1XwQ",
  refundPolicyurl: "https://thesparktech.sparktechdev.org/policy/refund-policy",
  firebaseConfig: {
    apiKey: "AIzaSyCC74TVyn0gEwBS8KC7wL66hnlRnH3U_5A",
    authDomain: "webtest-1b931.firebaseapp.com",
    projectId: "webtest-1b931",
    storageBucket: "webtest-1b931.appspot.com",
    messagingSenderId: "360830017288",
    appId: "1:360830017288:web:0334c8e7dc63670dabd6bd",
    measurementId: "G-JHZC8ERRT1"
  },
  isDebug: true,
  mapKey: "AIzaSyB34AiNIF8Rf9rq5oYo_T0jgmd2f9dwhao",
  tawkPropertyId: '65426086f2439e1631eab13d',
  tawkWidgetId: '1he5ki3jk',
};
