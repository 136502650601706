import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { SparkConfirmationService } from 'src/@spark/services/confirmation/confirmation.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import * as CryptoJS from 'crypto-js';
import { formatDate } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  deleteConfigForm: FormGroup
  constructor(private _http: HttpClient,
    private _fb: FormBuilder,
    private _fuseConfirmationService: SparkConfirmationService,
    private cookieService: CookieService,
    private _toaster: ToastrService) {

  }

  encrypt(data) {
    let key = environment.isDebug ? data : CryptoJS.SHA256(data);
    return key;
  }

  decryptAES(data) {
    return environment.isDebug ? data : CryptoJS.AES.decrypt(data, environment.passphrase).toString(CryptoJS.enc.Utf8)
  }


  encryptAES(data) {
    let key = environment.isDebug ? data : CryptoJS.AES.encrypt(data, environment.passphrase).toString()
    return key;
  }


  clearSession() {
    sessionStorage.clear()
    this.cookieService.deleteAll('/', environment.domain)
  }

  removeSession(username, role) {
    let accountList: any = this.getAccounts()
    let index = accountList.findIndex((acc) => acc.role == role && acc.username == username)
    accountList.splice(index, 1)
    this.clearCookie(username + "_" + "profile_token")
    if (accountList.length == 0) {
      this.clearCookie("personalSecret")
    }
    else
      this.setCookie("personalSecret", JSON.stringify(accountList))
    sessionStorage.clear()
  }

  setCookie(name, data) {
    let key = environment.isDebug ? environment.env + '-' + name : CryptoJS.SHA256(environment.env + '-' + name);
    var now = new Date();
    let devExp = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
    let prodExp = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 15);
    this.cookieService.set(
      key,
      (environment.isDebug ? data : CryptoJS.AES.encrypt(data, environment.passphrase).toString()),
      { domain: environment.domain, path: '/', expires: environment.production ? prodExp : devExp })
  }

  clearCookie(name) {
    let key = environment.isDebug ? environment.env + '-' + name : CryptoJS.SHA256(environment.env + '-' + name);
    this.cookieService.delete(key, '/', environment.domain)
  }

  getCookie(name, encrypt = false) {
    let key
    if (!encrypt) {
      key = (environment.isDebug) ? environment.env + '-' + name : CryptoJS.SHA256(environment.env + '-' + name);
    }
    else
      key = name
    return this.cookieService.get(key)
  }

  getAccounts() {
    let accountList: any[] = []
    let accData = JSON.parse(this.decryptAES(this.getCookie("personalSecret")))
    for (let user of accData) {
      try {
        accountList.push(user)
      }
      catch (e) {
        // return false;
      }
    }
    return accountList;
  }

  copyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  toFormData(data, form, name?) {
    if (typeof data === 'object' && data != undefined) {
      for (let key in data) {
        if (typeof data[key] === 'object' && data[key] != null && !(data[key] instanceof File)) {
          if (name)
            this.toFormData(data[key], form, name + '[' + key + ']')
          else {
            this.toFormData(data[key], form, key)
          }
        }
        else {
          let value = data[key];
          if (typeof data[key] === 'boolean') {
            value = data[key] ? 1 : 0
          }
          if (!name)
            form.append(key, value);
          else
            form.append(name + '[' + key + ']', value);
        }
      }

      if (data instanceof Array && data.length == 0) {
        let value = data;
        form.append(name, []);
      }
    }
    else {
      form.append(name, data);
    }

    return form;
  }

  getFileName(path) {
    return path.replace(/^.*[\\\/]/, '');
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: any[] = []): any {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }
      const label = child.snapshot.data['breadcrumb'];
      if (label) {
        breadcrumbs.push({ label, url });
      }

      return this.buildBreadCrumb(child, url, breadcrumbs);
    }
  }

  // password and confirm password check
  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  checkMaxValueLimit(controlName: string, limit) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      // set error on matchingControl if validation fails
      if (control.errors && !control.errors['limit']) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      if (control.value > limit) {
        control.setErrors({ limit: true });
      } else {
        control.setErrors(null);
      }
    }
  }
  checkMinValueLimit(controlName: string, limit) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      // set error on matchingControl if validation fails
      if (control.errors && !control.errors['limit']) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      if (control.value < limit) {
        control.setErrors({ limit: true });
      } else {
        control.setErrors(null);
      }
    }
  }


  showSuccess(message: String) {
    return this._toaster.success(message.toString(), '', { progressBar: true });
  }

  showSuccessSwal(message) {
    Swal.fire(
      {
        title: message,
        heightAuto: false,
        icon: 'success'
      }
    )
  }

  welcomeUser(message: String) {
    return this._toaster.success("Welcome to SPARK Technologies", 'Hey, ' + message, { progressBar: true });
  }

  showFailure(message: String) {
    return this._toaster.error(message.toString());
  }

  showFailureSwal(message) {
    Swal.fire(
      {
        title: message,
        heightAuto: false,
        icon: 'error'
      }
    )
  }

  showBottomFailure(message: String) {
    return this._toaster.error(message.toString(), '', { positionClass: 'toast-bottom-center' });
  }

  showWarning(message: String) {
    return this._toaster.warning(message.toString());
  }

  showBottomSuccess(message: String) {
    this._toaster.show(message.toString(), '', {
      positionClass: 'toast-bottom-center',
      toastClass: 'ngx-toastr process-toaster',
    });
  }
  showProcess(message: String, id) {
    return this._toaster.show(message.toString(), '',
      {
        positionClass: 'toast-bottom-center',
        toastClass: 'ngx-toastr process-toaster',
        timeOut: 0,
        tapToDismiss: false,
        progressBar: true,
        disableTimeOut: true,
        enableHtml: true,
      }).toastId = id;
  }

  removeProcess(id) {
    this._toaster.remove(id);
  }

  validationList: any[] = [
    { 'name': "At least one digit", "status": false },
    { 'name': "min 8 character", "status": false },
    { 'name': "At least one lower case", "status": false },
    { 'name': "At least one upper case", "status": false },
    { 'name': "At least one special character", "status": false },
  ];


  warningDialog(title, message, confirmText?, denyText?, confirmButtonColor?) {
    let parser = new DOMParser();
    let tg = parser.parseFromString(message, 'text/html').body;
    return Swal.fire({
      title: title,
      text: tg.textContent?.toString(),
      showCancelButton: true,
      icon: 'warning',
      reverseButtons: true,
      confirmButtonText: confirmText ?? "Yes",
      cancelButtonText: denyText ?? "No",
      confirmButtonColor: confirmButtonColor ?? 'green',
      heightAuto: false,
      customClass: {
        confirmButton: 'delete-button',
      },
      cancelButtonColor: '#dadada',
    });
  }

  deleteDialogue(data) {
    let parser = new DOMParser();
    let tg = parser.parseFromString('Are you sure to remove <b>' + data + '</b>?', 'text/html').body;
    return Swal.fire({
      text: tg.textContent?.toString(),
      showCancelButton: true,
      icon: 'warning',
      reverseButtons: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
      confirmButtonColor: 'red',
      heightAuto: false,
      customClass: {
        confirmButton: 'swal-delete-button',
        cancelButton: 'swal-cancel-button'
      },
      cancelButtonColor: '#dadada',
    });
  }

  deleteDialog(data) {
    // Build the config form
    this.deleteConfigForm = this._fb.group({
      title: 'Are you sure to remove ' + data + '?',
      message: '',
      icon: this._fb.group({
        show: true,
        name: 'heroicons_outline:user-remove',
        color: 'warn'
      }),
      actions: this._fb.group({
        confirm: this._fb.group({
          show: true,
          label: 'Yes, remove',

          color: 'warn'
        }),
        cancel: this._fb.group({
          show: true,
          label: 'Cancel'
        })
      }),
      dismissible: true
    });
    const dialogRef = this._fuseConfirmationService.open(this.deleteConfigForm.value);
    return dialogRef;
  }

  generatePassword(length?: number) {
    const passwordLength = length || 12;

    const lowerCharacters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    const upperCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const symbols = ['!', '?', '@', ')', '(', '%', '$', '#'];

    const getRandom = array => array[Math.floor(Math.random() * array.length)];

    let finalCharacters = '';

    finalCharacters = finalCharacters.concat(getRandom(upperCharacters));

    finalCharacters = finalCharacters.concat(getRandom(numbers));


    finalCharacters = finalCharacters.concat(getRandom(symbols));


    for (let i = 0; i < passwordLength - 3; i++) {
      finalCharacters = finalCharacters.concat(getRandom(lowerCharacters));
    }

    return finalCharacters.split('').sort(() => 0.5 - Math.random()).join('');
  }

  generateHoursInterval = (
    startHour,
    endHour,
    interval,
  ) => {
    let hoursList: any = [];

    for (let hour = startHour; hour <= endHour; hour++) {
      for (let minute = 0; minute < 60; minute += interval) {
        let period = hour < 12 ? 'AM' : 'PM';
        let hour12 = hour % 12 || 12;
        let formattedHour = `${hour12.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${period}`;
        hoursList.push(formattedHour);
      }
    }

    return hoursList;
  };

  public loadScript(url) {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  redirectTo(url) {
    window.location.href = url
  }

  redirectToNew(url) {
    window.open(url, '_blank')
  }

  dateConvert(date, format?) {
    return date ? formatDate(date, format ? format : 'yyyy-MM-dd', 'en-in') : ''
  }

  toProperCase(str) {
    let newStr = str.replace('-', ' ').replace('_', ' ')
    return newStr.charAt(0).toUpperCase() + newStr.slice(1);
  };
}
