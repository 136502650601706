import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatchMediaService } from '../../@spark/services/match-media.service';
import { MatDrawer, MatDrawerMode } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OverlayContainer } from '@angular/cdk/overlay';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SparkNavigationService } from 'src/@spark/components/navigation/navigation.service';
import { NavigationService } from '../core/navigation/navigation.service';
import { UserService } from '../core/user/user.service';
import { AuthService } from '../core/auth/auth.service';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { DOCUMENT, formatDate } from '@angular/common';
import { Subject } from 'rxjs';
import { Idle } from '@ng-idle/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { UtilityService } from '../core/common-service/utility.service';
import { TailwindService } from 'src/@spark/services/tailwind.service';
import { LayoutService } from './layout.service';
import { NotificationService } from '../core/messaging/notification.service';
import { CoreEnvironment } from '@angular/compiler/src/compiler_facade_interface';
import { ChangePasswordModelComponent } from '../components/home/change-password-model/change-password-model.component';
import { CookieService } from 'ngx-cookie-service';
import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  config;
  isSidebarFold = false;
  sidebarClosed = false;
  isDarkMode: boolean = false;

  contentMargin = 270;
  appVersion = environment.appVersion;
  mode: MatDrawerMode = 'side';
  userData;
  isLoading;

  scheme: 'dark' | 'light';
  layout;
  currentYear = new Date().getFullYear()

  orgURL = environment.orgURL

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  theme: any[] = [];
  themes: [string, any][];
  accountList: any[] = [];
  notifications: any[] = [];
  navigation = []

  personalKey = this._utility.encrypt("personalSecret")
  personalRoleKey = this._utility.encrypt("personal")
  businessRoleKey = this._utility.encrypt("business")

  constructor(
    private _sparkNavigationService: SparkNavigationService,
    private _navigationService: NavigationService,
    private _matchMedia: MatchMediaService,
    private _mediaObserver: MediaObserver,
    private _router: Router,
    private _sparkConfigService: SparkConfigService,
    private _authService: AuthService,
    private _userService: UserService,
    private _activatedRoute: ActivatedRoute,
    private _utility: UtilityService,
    private _translocoService: TranslocoService,
    private _tailwindConfigService: TailwindService,
    private _layoutService: LayoutService,
    private _notificationService: NotificationService,
    public dialog: MatDialog,
    private idle: Idle,
    @Inject(DOCUMENT) private _document: any,
    private overlayContainer: OverlayContainer) {

    this._navigationService.navigation$.subscribe((data: any) => {
      this.navigation = data
    })
  }

  @ViewChild('drawer') drawer: MatDrawer
  @ViewChild('rightDrawer') rightDrawer: MatDrawer

  ngOnInit() {

    // Get the themes
    this._tailwindConfigService.tailwindConfig$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((config) => {
        this.themes = Object.entries(config.themes);
      });

    this._sparkConfigService.config$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
        this.config = config;
        this.scheme = config.scheme;
        this.layout = config.layout;
        this.theme = config.theme;
        this._updateScheme();
        this._updateLayout();
        this._updateTheme();
      });

    this._router.events.subscribe(() => {
      this._updateLayout();
      if (this.drawer) {
        const isActive = this._mediaObserver.isActive('lt-md');
        if (isActive) {
          this.drawer.close();
        }
      }
    });

    // this._notificationService.notifications$.pipe(
    //   takeUntil(this._unsubscribeAll)).subscribe((data) => {
    //     data ? this.notifications = data : this.notifications = []
    //   })

    this._matchMedia.onMediaChange.subscribe((data) => {
      // Get the active status
      const isActive = this._mediaObserver.isActive('lt-md');
      if (isActive) {
        this.sidebarClosed = true;
        this.mode = 'over';
      }
      else {
        this.sidebarClosed = false;
        this.mode = 'side';
      }
    })

    this._userService.user$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((data) => {
        this.userData = data
        let creds = this._utility.decryptAES(this._utility.getCookie(this.personalKey))
        let credData = JSON.parse(creds)
        for (let key in credData) {
          this.accountList.push({ ...credData[key] })
        }
      })
  }

  switchAccount(i) {
    window.open(environment.myAccountDomain + "?accIndex=" + i);
  }

  addNewAccount() {
    window.open(environment.loginDomain);
  }

  logout() {
    let params = new HttpParams();
    let role = _.cloneDeep(this.userData.user_login.user_role_name.user_role_drc_key)
    params = params.set('login', role == 1 ? this.businessRoleKey : this.personalRoleKey);
    this._authService.signOut().then((data) => {
      window.location.href = environment.loginDomain + "?" + params
    }).finally(() => {
      window.location.href = environment.loginDomain + "?" + params
      this._utility.removeSession(this.userData.user_login.username, this.userData.user_login.user_role_name.user_role_drc_key)
    });
  }

  getNotifications() {
    this._layoutService.getNotifications().then((data: any) => {
      this.notifications = data
    })
  }

  calculateUnread() {
    if (this.notifications)
      return this.notifications.filter((val) => val.notification_status == 0).length
    else
      return 0;
  }

  markAllRead() {
    this._layoutService.markAllRead().then((data) => {
      this.getNotifications()
    })
  }

  markRead(key, notificationStatus) {
    this._layoutService.markRead(key, notificationStatus).then((data) => {
      this.getNotifications()
    })
  }

  removeNotification(key) {
    this._layoutService.removeNotification(key).then((data) => {
      this.getNotifications()
    })
  }

  foldSidebar() {
    const isActive = this._mediaObserver.isActive('gt-sm')
    if (isActive) {
      this.isSidebarFold = !this.isSidebarFold;
      this._sparkNavigationService.onSidebarFold.next(this.isSidebarFold)
    }
    else {
      this.drawer.toggle();
    }
    if (this.isSidebarFold) {
      this.contentMargin = 70;
    }
    else {
      this.contentMargin = 270;
    }
  }

  openSettings() {
    this.rightDrawer.toggle();
  }

  setScheme(scheme): void {
    this._sparkConfigService.config = { scheme };
  }

  setTheme(theme): void {
    this._sparkConfigService.config = { theme };

  }

  setSession(idleTime): void {
    this._sparkConfigService.config = { idleTime };
    this.idle.watch()
  }

  private _updateScheme(): void {
    this._document.body.classList.remove('light', 'dark');
    if (this.scheme == 'dark' ||
      this.scheme == 'light') {
      this._document.body.classList.add(this.scheme);
    }
    else {
      this._document.body.classList.add('light');
    }
  }

  private _updateLayout(): void {
    if (
      this.config.layout == 'empty' ||
      this.config.layout == 'default') {
      let route = this._activatedRoute;
      while (route.firstChild) {
        route = route.firstChild;
      }
      this.layout = this.config.layout;
      const layoutFromQueryParam = (route.snapshot.queryParamMap.get('layout'));
      if (layoutFromQueryParam) {
        this.layout = layoutFromQueryParam;
        if (this.config) {
          this.config.layout = layoutFromQueryParam;
        }
      }

      // 3. Iterate through the paths and change the layout as we find
      // a config for it.
      //
      // The reason we do this is that there might be empty grouping
      // paths or componentless routes along the path. Because of that,
      // we cannot just assume that the layout configuration will be
      // in the last path's config or in the first path's config.
      //
      // So, we get all the paths that matched starting from root all
      // the way to the current activated route, walk through them one
      // by one and change the layout as we find the layout config. This
      // way, layout configuration can live anywhere within the path and
      // we won't miss it.
      //
      // Also, this will allow overriding the layout in any time so we
      // can have different layouts for different routes.

      const paths = route.pathFromRoot;
      paths.forEach((path) => {
        // Check if there is a 'layout' data
        if (path.routeConfig && path.routeConfig.data && path.routeConfig.data['layout']) {
          // Set the layout
          this.layout = path.routeConfig.data['layout'];
        }
      });
    }
    else {
      this.layout = 'default';
    }
  }

  private _updateTheme(): void {
    // Find the class name for the previously selected theme and remove it
    this._document.body.classList.forEach((className: string) => {
      if (className.startsWith('theme-')) {
        this._document.body.classList.remove(className, className.split('-')[1]);
      }
    });

    // Add class name for the currently selected theme
    this._document.body.classList.add(`theme-${this.theme}`);
  }
}
