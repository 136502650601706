import { HttpClient, HttpHeaders, HttpParams, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  baseUrl = environment.APIUrl;
  constructor(private _http: HttpClient) { }

  get(path) {
    return this._http.get(this.baseUrl + path);
  }

  getTest(path) {
    return this._http.get("https://eb31-103-164-196-14.in.ngrok.io/api/" + path);
  }

  download(path) {
    return this._http.get(this.baseUrl + path, { responseType: 'blob', observe: 'response' }).pipe(map((res: any) => {
      if (res.body) {
        if (res.headers.get('Content-Type') == "application/json") {
          return null;
        }
        else {
          let data = {
            data: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('File-Name')
          }
          return data;
        }
      }
      return null
    }));
  }

  getWithAuth(path) {
    return this._http.get(this.baseUrl + path, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('accessToken') } });
  }
  post(path, data, skipAuth?, skipInterceptor?) {
    let headers = new HttpHeaders();
    if (skipAuth)
      headers = headers.append('skipAuth', 'true');
    if (skipInterceptor)
      headers = headers.append('skipInterceptor', 'true');
    return this._http.post(this.baseUrl + path, data,
      {
        headers: headers
      });
  }
  postTest(path, data) {
    return this._http.post("https://38a2-103-164-196-14.in.ngrok.io/api/" + path, data);
  }
  postWithAuth(path, data) {
    return this._http.post(this.baseUrl + path, data, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('accessToken') } });
  }
}
